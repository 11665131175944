<div>
  <h3>Configured FeaturePrint Stations - Current Status</h3>
  <h4 *ngIf="!(stationList && stationList.length > 0)">N/A</h4>
  <table class="table table-sm table-hover table-striped" *ngIf="stationList && stationList.length > 0">
    <tbody>
    <ng-container *ngFor="let s of stationList">
      <tr>
        <td [ngClass]="s.condition">{{s.condition}}</td>
        <td>{{s.stationId}} </td>
        <td>{{getUptimeDowntime(s)}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
