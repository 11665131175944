<mat-card *ngIf="physicalRegistration" class="record-card">
  <div>
    <table class="table table-sm table-hover table-striped">
      <tr style="border:none">
        <th colspan="2" class="record-title">FeaturePrint</th>
      </tr>
      <tbody>
      <tr class="preview-wrapper-row">
        <td class="preview-wrapper" colspan="2">
          <img alt="preview" class="preview preview-sm"
               [src]="previewImageUrl | previewImage | async"
               onerror="this.style.display='none'"
               onload="this.style.display='inline-block'"
          >
        </td>
      </tr>
      <tr>
        <td class="preview-label">FeaturePrint Id:</td>
        <td>{{physicalRegistration.registration?.recordId}}</td>
      </tr>
      <tr>
        <td class="preview-label">Context:</td>
        <td>{{physicalRegistration.contextId}}</td>
      </tr>
      <tr>
        <td class="preview-label">Station:</td>
        <td>{{physicalRegistration.stationId}}</td>
      </tr>
      <tr>
        <td class="preview-label">FeaturePrint Status</td>
        <td>{{physicalRegistration.status}}</td>
      </tr>
      <tr>
        <td class="preview-label">Created</td>
        <td>{{physicalRegistration.created | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</mat-card>


