/* tslint:disable:no-console */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MonitoringService, CurrentStatus} from '../monitoring.service';
import {PeriodicRefreshService} from '../../utility/periodic-refresh.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ae-st-health',
  templateUrl: './station-health.component.html',
  styleUrls: ['./station-health.component.scss']
})
export class StationHealthComponent implements OnInit, OnDestroy {
  private stationStream: Observable<CurrentStatus[]> = new Observable;
  private refreshSub: Subscription | null = null;
  stationList: CurrentStatus[] = [];

  callStatus = 'ready';
  @Input()
  dataSets: string[] | null = null;

  constructor(private stationApi: MonitoringService, private refreshSvc: PeriodicRefreshService) {
  }

  ngOnInit(): void {
    this.refresh();
    this.setRefreshSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeRefresh();
  }

  private setRefreshSubscription(): void {
    this.refreshSub = null;
    this.unsubscribeRefresh();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(t => {
      this.refresh();
    });
  }

  private unsubscribeRefresh(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  private formatHours(numberOfHours: number): string {
    const days = Math.floor(numberOfHours / 24);
    const remainder = numberOfHours % 24;
    const hours = Math.floor(remainder);
    const minutes = Math.floor(60 * (remainder - hours));
    return days + 'd ' + hours + ' h ' + minutes + ' min';
  }

  private formatDowntime(stationStatus: CurrentStatus): string {
    const millis = (new Date()).getTime() - Date.parse(stationStatus.lastReported);
    const hours = millis / 3600000; // millis / 1000 / 60 / 60;
    return this.formatHours(hours);
  }

  public getUptimeDowntime(stationStatus: CurrentStatus): string {
    let response = 'unknown';

    if (stationStatus) {
      if (stationStatus.meta && stationStatus.meta.uptimeHours) {
        response = 'UP: ' + this.formatHours(stationStatus.meta.uptimeHours);
      } else {
        if (stationStatus.condition === 'ERROR' && stationStatus.state === 'OFFLINE') {
          response = 'DOWN: ' + this.formatDowntime(stationStatus);
        }
      }
    }
    return response;
  }

  public refresh(): void {
    this.callStatus = 'working...';
    this.stationStream = this.stationApi.getDeviceStatus();

    const sub = this.stationStream.subscribe({
      next: (stations) => {
        this.stationList = stations;
        this.callStatus = 'ready';
        sub.unsubscribe();
      },
      error: (error => {
        this.callStatus = error.toString();
      })
    });
  }
}
