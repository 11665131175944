<ae-nav></ae-nav>

<div>
    <div class="search-filter">
        <ae-search-filter class="search-filter"
                          [searchTitle]="'FeaturePrints Search'"
                          [stationList]="lookups.stations"
                          [includeDataSetSearch]="false"
                          [dataSetList]="lookups.dataSets"
                          [contextsList]="lookups.contexts"
                          [statusList]="lookups.registrationStatusList"
                          [multipleStations]="true"
                          (searchEvent)="searchHandler($event)">
        </ae-search-filter>
    </div>

    <div #regTop class="search-filter">
        <mat-card *ngIf="registrations && registrations.length > 0">
            <div class="header-menu">
                <h3 class="header-menu-title">FeaturePrints count: {{totalRegistrationLength || 0}}</h3>
                <button *ngIf="currentCardId" mat-button (click)="clearCurrent();">
                    clear selection
                </button>
            </div>

            <div class="scroll-pane scroll-pane-all"
                 [ngClass]="{'scroll-pane-detail': currentCardId}">
                <table class="table table-sm table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Context ID</th>
                        <th>Station</th>
                        <th>Status</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of registrations; index as i" #regList
                        [ngClass]="{'selected': currentCardId == r?.physicalIdentifier?.physicalId}"
                        class="link-active"
                        (click)="currentCardId == r?.physicalIdentifier?.physicalId ? clearCurrent() : viewCapture(i, r.physicalIdentifier.physicalId)"
                    >
                        <td>
                            <a class="link-active" matTooltip="click to open FeaturePrint in new tab"
                               target="_blank"
                               [routerLink]="['/registrations/physical', r.physicalIdentifier.physicalId]"> {{r?.physicalIdentifier?.itemId}} </a>
                        </td>
                        <td matTooltip="click row for details">{{r.registration.contextId}}</td>
                        <td matTooltip="click row for details">{{r.registration.stationId}}</td>
                        <td matTooltip="click row for details">{{r.physicalIdentifier.status}}</td>
                        <td matTooltip="click row for details">{{r.physicalIdentifier.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card>
        <mat-paginator #paginator
                       [length]="totalRegistrationLength || 0"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       [hidden]="totalRegistrationLength < 1"
                       (page)="pageHandler($event)"
        ></mat-paginator>
        <mat-card *ngIf="registrations &&  registrations.length === 0" class="no-match">
            <mat-card-content>
                No Matching Records
            </mat-card-content>
        </mat-card>

        <ae-physical-reg *ngIf="currentCardId"
                         [cardId]="currentCardId"
                         [canChangeStatus]="true"
                         [fullView]="false"
                         (registrationChange)="registrationChangeHandler($event)">
        </ae-physical-reg>
    </div>
</div>
