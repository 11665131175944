import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchCriteria } from '../../modules/record/record-api.service';
import {
  StationSearchItem,
  StatusSearchItem,
} from '../../modules/core.service';
import { DataSetContext } from '../../modules/record/context-models';
import { MatSelectChange } from '@angular/material/select';
import { dateRangeValidator } from './search-filter-component.directive';

export class QuickSearchRangeItem {
  rangeInMinutes: number;
  display: string;

  constructor(rangeInMinutes: number, display: string) {
    this.rangeInMinutes = rangeInMinutes;
    this.display = display;
  }
}

@Component({
  selector: 'ae-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  @Output() searchEvent: EventEmitter<SearchCriteria> =
    new EventEmitter<SearchCriteria>();
  @Input() searchTitle!: string;
  @Input() includeDateSearch = true;
  @Input() includeStationSearch = true;
  @Input() includeItemIdSearch = true;
  @Input() includeDataSetSearch = true;
  @Input() includeContextIdSearch = true;
  @Input() includeStatusSearch = true;
  @Input() stationList: StationSearchItem[] = [];
  @Input() dataSetList?: string[] = ['*'];
  @Input() contextsList: DataSetContext[] = [];
  @Input() statusList: StatusSearchItem[] = [{ val: null, display: 'all' }];
  @Input() multipleStations = true;
  @Input() quickSearchList: QuickSearchRangeItem[] = [
    { rangeInMinutes: 5, display: 'Last 5 Minutes' },
    { rangeInMinutes: 10, display: 'Last 10 Minutes' },
    { rangeInMinutes: 15, display: 'Last 15 Minutes' },
    { rangeInMinutes: 20, display: 'Last 20 Minutes' },
    { rangeInMinutes: 30, display: 'Last 30 Minutes' },
    { rangeInMinutes: 60, display: 'Last Hour' },
    { rangeInMinutes: 120, display: 'Last 2 Hours' },
    { rangeInMinutes: 240, display: 'Last 4 Hours' },
    { rangeInMinutes: 360, display: 'Last 6 Hours' },
    { rangeInMinutes: 1440, display: 'Last Day' },
    { rangeInMinutes: 10080, display: 'Last Week' },
  ];
  @Input() isExpanded = true;

  @Input() set setStartDate(val: Date) {
    this.startDate.setValue(val);
  }

  @Input() set setEndDate(val: Date) {
    this.endDate.setValue(val);
  }

  @Input() set setStations(val: string[]) {
    this.stations.setValue(val);
  }

  @Input() set setDataSets(val: string[]) {
    this.dataSets.setValue(val);
  }

  @Input() set setStatus(val: string) {
    this.statusCtl.setValue(val);
  }

  @Input() set setItemId(val: string) {
    this.itemId.setValue(val);
  }

  @Input() set setContext(val: DataSetContext) {
    this.contexts.setValue(val);
  }

  searchForm: FormGroup;
  searchQuickRange = new FormControl();
  startDate = new FormControl();
  endDate = new FormControl();
  stations = new FormControl();
  dataSets = new FormControl();
  statusCtl = new FormControl();
  itemId = new FormControl();
  contexts = new FormControl();
  dateForm = new FormGroup({
    startDate: this.startDate,
    endDate: this.endDate,
  },[dateRangeValidator]);

  constructor(formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      quickSearch: this.searchQuickRange,
      dateForm: this.dateForm,
      stations: this.stations,
      dataSets: this.dataSets,
      status: this.statusCtl,
      itemId: this.itemId,
      context: this.contexts,
    });
  }

  ngOnInit(): void {
    let currentDs = this.dataSets.value as string[] || [];
    this.dataSets.valueChanges.subscribe((value: string[]) => {
      let newVal: string[];
      const oldAllSelected = currentDs.some((s) => s === '*');
      const allIdx = value.indexOf('*');
      if (allIdx > -1 && !oldAllSelected) {
        newVal = ['*'];
      } else if (allIdx > -1 && oldAllSelected && value.length > 1) {
        newVal = value.slice(0, value.length);
        newVal.splice(allIdx, 1);
      } else {
        newVal = value.slice(0, value.length);
      }
      currentDs = newVal;
      this.dataSets.setValue(newVal, {
        onlySelf: false,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: true,
      });
    });

    // init search range with first value
    this.searchQuickRange.setValue(null);
  }

  onSubmit(): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.isExpanded = false;
    const formValue = this.searchForm.value;
    const dateValue = this.dateForm.value;
    const stations = formValue.stations ? formValue.stations : [];
    const dataSets = formValue.dataSets ? formValue.dataSets : [];
    const status = formValue.status ? formValue.status : null;
    this.setDateControls();
    const searchCrit = new SearchCriteria(
      dateValue.startDate,
      dateValue.endDate,
      stations,
      dataSets,
      status,
      formValue.itemId,
      formValue.context
    );
    this.searchEvent.emit(searchCrit);
  }

  cancelSearch(): void {
    this.isExpanded = false;
  }

  resetSearch(): void {
    this.searchForm.reset();
  }

  onChangeStartDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeEndDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeQuickSearch(event: MatSelectChange): void {
    if (!event.value) {
      this.startDate.setValue(null);
      this.endDate.setValue(null);
      return;
    }
    this.setDateControls();
  }

  private setDateControls(): void {
    if (this.searchQuickRange.value) {
      const endMillis = Date.now();
      const startMillis = endMillis - this.searchQuickRange.value * 60 * 1000;
      this.endDate.setValue(new Date(endMillis));
      this.startDate.setValue(new Date(startMillis));
    }
  }
}
