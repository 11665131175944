import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RecordApiService, SearchCriteria} from '../../modules/record/record-api.service';
import {PeriodicRefreshService} from '../../modules/utility/periodic-refresh.service';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'ae-fp-stats',
  templateUrl: './featureprint-stats.component.html',
  styleUrls: ['./featureprint-stats.component.scss']
})
export class FeatureprintStatsComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription | null = null;

  private dataSets: string[] | null = null;

  @Input() set setDataSets(val: string[]) {
    if (val) {
      this.dataSets = val;
      this.getCounts();
    }
  }

  dailyRegs = 0;
  dailyAuths = 0;
  totalRegs = 0;
  totalAuths = 0;

  constructor(private recordApi: RecordApiService,
              private refreshSvc: PeriodicRefreshService) {
  }

  ngOnInit(): void {
    this.getCounts();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      this.getCounts();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
  }

  getCounts(): void {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // today's registrations
    this.recordApi.registrationsDashboardCountsByDay(this.dataSets as Array<string>, start).subscribe(cnts => {
      if (cnts.length > 0) {
        const countByDay = cnts[cnts.length - 1];
        this.dailyRegs = countByDay.count;
      }
    });

    // total registrations
    this.recordApi.registrationsDashboardCount({dataSets: this.dataSets} as SearchCriteria).subscribe(cnt => {
      this.totalRegs = cnt?.count || 0;
    });

    // today's auths
    this.recordApi.authenticationsDashboardCountsByDay(this.dataSets as Array<string>, start).subscribe(cnts => {
      if (cnts.length > 0) {
        const countByDay = cnts[cnts.length - 1];
        this.dailyAuths = countByDay.count;
      }
    });

    // total authentications
    this.recordApi.authenticationsDashboardCount({dataSets: this.dataSets} as SearchCriteria).subscribe(cnt => {
      this.totalAuths = cnt?.count || 0;
    });

  }
}
