<ae-nav></ae-nav>
<div>
    <h1 class="main-view"> Dashboard </h1>

    <div class="dashboard-container">

        <div class="row" *ngIf="false">
            <div class="col-md-12">
                <ae-search-filter
                        class="search-filter"
                        [searchTitle]="'Select DataSets for Dashboard'"
                        [includeDataSetSearch]="true"
                        [includeDateSearch]="false"
                        [includeStationSearch]="false"
                        [includeItemIdSearch]="false"
                        [includeContextIdSearch]="false"
                        [includeStatusSearch]="false"
                        [isExpanded]="false"
                        [dataSetList]="lookups.dataSets"
                        [setDataSets]="selectedDataSets"
                        (searchEvent)="searchHandler($event)">
                    >
                </ae-search-filter>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 fp-graph">
                <h2>Total Daily FeaturePrints and Identifications</h2>
                <ae-fp-chart [setDataSets]="selectedDataSets"></ae-fp-chart>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-card class="fp-card">
                    <mat-card-content class="fp-content">
                        <ae-st-health [dataSets]="selectedDataSets"></ae-st-health>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-4">
                <mat-card class="fp-card">
                    <mat-card-content class="fp-content">
                        <ae-fp-stats [setDataSets]="selectedDataSets"></ae-fp-stats>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-4">
                <mat-card class="fp-card">
                    <mat-card-content class="fp-content">
                        <ae-non-matches [dataSets]="selectedDataSets"></ae-non-matches>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
