import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../user.service';
import {Role, UserInfo} from '../user-info';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {timer, zip} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {ConfigService} from "../../base/config.service";
import {DemoConfig} from "../../../app.module";
import {AlitheonError} from "../../base/alitheon-error";

export class UserStatus {
  enabled: boolean;
  label: string;

  constructor(enabled: boolean, label: string) {
    this.enabled = enabled;
    this.label = label;
  }
}

export const NO_ROLES = new Role("no-roles", "NO_ROLES");

export const userFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const isNew = control.get('userIsNew');
  // console.log(control, isNew, isNew.value);
  if (isNew && isNew.value === true) {
    const userName = control.get('userName');
    const invalid = false;
    // console.log('valid?', invalid, pwd.value);
    return invalid ? {invalidUserName: true} : null;
  } else {
    return null;
  }
};

@Component({
  selector: 'ae-user-mgt',
  templateUrl: './user-mgt.component.html',
  styleUrls: ['./user-mgt.component.scss']
})
export class UserMgtComponent implements OnInit {

  constructor(private userService: UserService, private configSvc: ConfigService<DemoConfig>, formBuilder: FormBuilder) {
    this.filterForm = formBuilder.group({
      filterStatus: this.filterStatusFormCtl,
      filterRoles: this.filterRolesFormCtl
    });

    this.userForm = formBuilder.group({
      userName: this.userNameFormCtl,
      userRoles: this.userRolesFormCtl,
      // userDataSets: this.userDataSetsFormCtl,
      userEnabled: this.userEnabledFormCtl,
      userIsNew: this.userIsNewCtl
    }, {validators: userFormValidator});
  }

  @ViewChild('usersTop') usersTopElement!: ElementRef;
  @ViewChildren('usersList') usersListElements!: QueryList<ElementRef>;

  showFilter = true;
  currentUserId: string | null = null;
  currentUser: UserInfo | null = null;
  users: UserInfo[] | null = null;
  availableRoles: Role[] | null = null;
  searchRoles: Role[] | null = null;
  // availableDataSets: string[] | null = null;

  ENABLED_STATUS = new UserStatus(true, 'Enabled');
  DISABLED_STATUS = new UserStatus(false, 'Disabled');
  statuses: UserStatus[] = [this.ENABLED_STATUS, this.DISABLED_STATUS];

  filterForm: FormGroup;
  filterStatusFormCtl = new FormControl();
  filterRolesFormCtl = new FormControl();

  // user editing
  inEdit = false;
  showDelete = false;
  userFormTitle = '';
  userForm: FormGroup;
  userNameFormCtl = new FormControl();
  userRolesFormCtl = new FormControl();
  // userDataSetsFormCtl = new FormControl();
  userEnabledFormCtl = new FormControl();
  userIsNewCtl = new FormControl();
  formErrors: string[] = [];


  ngOnInit(): void {
    const rolesSub = this.userService.listRoles();
    const streams: Observable<any>[] = [rolesSub];
    const zipped = zip(...streams);
    zipped.subscribe({
      next: ([roles, dataSets]) => {
        this.availableRoles = roles;
        this.searchRoles = JSON.parse(JSON.stringify(roles)) as Role[];
        this.searchRoles?.splice(0, 0, NO_ROLES);
        this.resetFilter();
      },
      error: errors => {
        console.log('Error initializing user management search', ...errors);
      }
    });
  }

  protected clearCurrent(): void {
    this.currentUser = null;
    this.currentUserId = null;
    if (this.userIsNewCtl.value !== true) {
      this.cancelEdit();
    }
  }

  cancelFilter(): void {
    this.showFilter = false;
  }

  resetFilter(): void {
    const statuses = this.statuses
      .filter(it => it === this.ENABLED_STATUS)
      .map(it => it.enabled);
    const roles = this.searchRoles!.map(it => it.id);
    this.filterStatusFormCtl.setValue(statuses);
    this.filterRolesFormCtl.setValue(roles);
  }

  doFilter(): void {
    this.users = null;
    this.showFilter = false;
    const formValue = this.filterForm.value;
    const roles: string[] = formValue.filterRoles;
    const status: boolean[] = formValue.filterStatus;
    this.userService.listUsers().subscribe(users => {

      // filter the full list against the desired filter params
      const filtered = users
        .filter(user => user.roles
          .some(role => roles.includes(role.id)) || (roles.includes(NO_ROLES.id) && user.roles.length == 0)
        )
        .filter(user => status.some(s => s === user.enabled));

      this.users = filtered;
    });
  }

  protected selectCurrentAndScroll(id: string, i: number, scroll: boolean): void {
    this.currentUserId = id;
    this.setCurrentUser(this.users![i]);
    this.showDelete = true;
    if (scroll && id) {
      timer(50).subscribe(() => {
        this.usersListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.usersTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected selectCurrent(id: string, i: number): void {
    const doScroll: boolean = this.currentUserId ? false : true;
    this.selectCurrentAndScroll(id, i, doScroll);
    this.showDelete = true;
  }

  private setCurrentUser(user: UserInfo): void {
    this.currentUser = user;
    this.userNameFormCtl.setValue(user.username);
    this.userRolesFormCtl.setValue(user.roles.map(r => r.id));
    this.userEnabledFormCtl.setValue(user.enabled);
    this.userIsNewCtl.setValue(false);
    this.userFormTitle = 'Edit User';
    this.userNameFormCtl.disable();
    this.inEdit = true;
  }

  newUser(): void {
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    this.userIsNewCtl.setValue(true);
    this.userEnabledFormCtl.setValue(true);
    this.userFormTitle = 'Enter New User';
    this.inEdit = true;
    this.showDelete = false;
  }

  cancelEdit(): void {
    this.clearFormErrors();
    this.inEdit = false;
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    if (!this.users || this.users.length < 1) {
      this.showFilter = true;
    }
  }

  protected resetUserForm(): void {
    this.userFormTitle = '';
    this.userNameFormCtl.setValue('');
    this.userNameFormCtl.enable();
    this.userEnabledFormCtl.setValue(false);
    this.userRolesFormCtl.setValue([]);
    this.userIsNewCtl.setValue(null);
    this.userForm.reset();
  }

  doDelete(): void {
    if (this.userForm.valid) {
      if (confirm("Are you sure you wish to DELETE this user?  This is a irreversible operation!!")) {
        this.userService.deleteUser((this.currentUserId) as string).subscribe({
          next: () => {
            this.cancelEdit();
            this.doFilter();
          },
          error: ((error: AlitheonError) => {
            console.log('delete user error', error);
            alert(`Error DELETING user. ${error.userMessage}`)
          })
        })
      }
    }
  }

  doUpdate(): void {
    if (this.userForm.valid) {
      this.clearFormErrors();
      const frm = this.userForm.value;
      const userId = (frm.userIsNew === true) ? null : this.currentUserId;
      const userRoleIds = (frm.userRoles || []) as string[];
      const userRoles = this.availableRoles!.filter(r => userRoleIds.some(i => i == r.id));
      const userInfo = new UserInfo(userId as string, frm.userName, frm.userEnabled, userRoles, [], this.configSvc.getConfig().realm);
      const _this = this;

      // create user
      if (frm.userIsNew) {
        this.userService.createUser(userInfo).subscribe({
          next: user => {
            this.cancelEdit();
            this.doFilter();
          },
          error: ((error: AlitheonError) => {
            console.log('ERROR creating user', error);
            if (!_this.formErrors.includes(error.message as string)) {
              _this.formErrors.push(error.message as string);
            }
          })
        });
      } else {
        /* update user*/
        const streams: Observable<any>[] = [];

        this.userService.updateUser(userInfo).subscribe({
          next: user => {
            this.cancelEdit();
            this.doFilter();
          },
          error: ((error: AlitheonError) => {
            console.log('ERROR udating user', error);
            if (!_this.formErrors.includes(error.message as string)) {
              _this.formErrors.push(error.message as string);
            }
          })
        });
      }
    }
  }

  canSubmit(): boolean {
    let can = false;
    if (this.userIsNewCtl.value === true) {
      can = this.userForm?.errors == null;
    } else {
      can = (this.userRolesFormCtl.dirty || this.userEnabledFormCtl.dirty)
        && this.userForm?.errors == null;
    }

    // console.log(this.userForm, this.userForm.dirty, this.userForm.valid,  this.userForm?.errors, this.userIsNewCtl.value, can);
    return can;
  }

  clearFormErrors(): void {
    this.formErrors = [];
  }

  formatRoles(roles: Role[]): string {
    return roles?.map(role => role.name).join(", ");
  }
}
