import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './modules/app-routing.module';
import {MatIconModule} from '@angular/material/icon';
import {AuthenticationComponent} from './components/authentication/authentication.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AngularMaterialModule} from './modules/angular-material.module/angular-material.module.module';
import {MatDialogModule} from '@angular/material/dialog';
import {RegistrationListComponent} from './components/registration-list/registration-list.component';
import {AuthenticationListComponent} from './components/authentication-list/authentication-list.component';
import {SearchFilterComponent} from './components/search-filter/search-filter.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import {PhysicalRegistrationComponent} from './components/physical-registration/physical-registration.component';
import {FeatureprintStatsComponent} from './components/featureprint-stats/featureprint-stats.component';
import {FeatureprintChartComponent} from './components/featureprint-chart/featureprint-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NonMatchesComponent} from './components/non-matches/non-matches.component';
import {CoreModule} from "./modules/core.module";
import {MonitoringModule} from "./modules/monitoring/monitoring.module";
import {UtilityModule} from "./modules/utility/utility.module";
import {NavComponent} from "./components/nav/nav.component";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./modules/base/config.service";
import {AppConfig} from "./modules/base/app-config";
import {lastValueFrom} from 'rxjs';
import {AuthZComponent} from "./modules/user/authz/auth-z.component";
import {UserMgtWrapperComponent} from "./components/user-mgt-wrapper/user-mgt-wrapper.component";
import {UserModule} from "./modules/user/user.module";

export interface DemoConfig extends AppConfig {
}

function initializeApp(configSvc: ConfigService<DemoConfig>, httpClient: HttpClient): () => Promise<boolean> {
    return async () => {
        const config = await lastValueFrom(httpClient
            .get("/assets/config/appConfig.json"))
        configSvc.setConfig(config as DemoConfig);
        return true;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        AuthenticationComponent,
        AuthenticationListComponent,
        RegistrationComponent,
        RegistrationListComponent,
        SearchFilterComponent,
        PhysicalRegistrationComponent,
        FeatureprintStatsComponent,
        FeatureprintStatsComponent,
        FeatureprintChartComponent,
        NonMatchesComponent,
        NavComponent,
        AuthZComponent,
        UserMgtWrapperComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CoreModule,
        MatIconModule,
        MatDialogModule,
        AngularMaterialModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxChartsModule,
        MonitoringModule,
        UtilityModule,
        UserModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ConfigService, HttpClient],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: []
})
export class AppModule {
}
