import {Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {AuthenticationComponent} from '../components/authentication/authentication.component';
import {RegistrationComponent} from '../components/registration/registration.component';
import {LogoutComponent} from './user/logout/logout.component';
import {AuthenticationListComponent} from '../components/authentication-list/authentication-list.component';
import {RegistrationListComponent} from '../components/registration-list/registration-list.component';
import {PhysicalRegistrationComponent} from '../components/physical-registration/physical-registration.component';
import {BaseService} from "./base/base.service";
import {AuthZComponent} from "./user/authz/auth-z.component";
import {UserService} from "./user/user.service";
import {UserMgtWrapperComponent} from "../components/user-mgt-wrapper/user-mgt-wrapper.component";
import {LoginComponent} from "./user/login/login.component";

/*
  todo: if we continue with Angular the route guard will need to be updated to use a functional guard as the class based is depricated and will eventually be removed
   See https://linear.app/alitheon/issue/BK-158/update-portal-authguard-routing-to-functional-paradigm
*/
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private baseSvc: BaseService, private api: UserService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let _this = this;
        console.log('auth-guard, token expired:', this.baseSvc.isTokenExpired(), route, state);
        if (this.baseSvc.isTokenExpired()) {
            const refreshObserver = {
                next(value: any) {
                    console.log('auth guard token refresh', value);
                    return true;
                },
                error(e: any) {
                    console.log('auth guard token refresh error', e);
                    _this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}}).then(r => true);
                    return false;
                },
                complete() {
                    console.log('auth guard token refresh complete');
                    return true;
                }
            };
            this.api.refreshToken().subscribe(refreshObserver);
        }

        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/authz'], {queryParams: {redirectUrl: state.url}}).then(r => true);
        return true;
    }

}

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'authz',
        component: AuthZComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'user-mgt',
        component: UserMgtWrapperComponent,
        canActivate: [AuthGuard]

    },
    {
        path: 'authentications',
        component: AuthenticationListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'authentication/:authenticationId',
        component: AuthenticationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'registrations',
        component: RegistrationListComponent,
        canActivate: [AuthGuard]

    },
    {
        path: 'registrations/physical/:physicalId',
        component: PhysicalRegistrationComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'registrations/:registrationId',
        component: RegistrationComponent,
        canActivate: [AuthGuard]

    },
    {
        path: '**',
        redirectTo: '/dashboard'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {
}

