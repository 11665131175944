<ae-nav></ae-nav>
<div>

    <div>
        <div class="search-filter">
            <ae-search-filter class="search-filter"
                              [searchTitle]="'Identifications Search'"
                              [stationList]="lookups.stations"
                              [includeDataSetSearch]="false"
                              [dataSetList]="lookups.dataSets"
                              [contextsList]="lookups.contexts"
                              [statusList]="lookups.authenticationStatusList"
                              [multipleStations]="true"
                              (searchEvent)="searchHandler($event)">
            </ae-search-filter>
        </div>

        <div #authTop class="search-filter">
            <mat-card *ngIf="authentications && authentications.length > 0">
                <div class="header-menu">
                    <h3 class="header-menu-title">Identifications count: {{totalAuthenticationsLength || 0}}</h3>
                    <button *ngIf="currentDisplayId" mat-button (click)="clearCapture();">
                        clear selection
                    </button>
                </div>

                <div class="scroll-pane scroll-pane-all"
                     [ngClass]="{'scroll-pane-detail': currentDisplayId}">
                    <table class="table table-sm table-hover table-striped">
                        <thead>
                        <tr>
                            <th>Identification ID</th>
                            <th>Context ID</th>
                            <th>Match</th>
                            <th>Item ID</th>
                            <th>Station</th>
                            <th>User ID</th>
                            <th>Status</th>
                            <th>Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let a of authentications; index as i" #authList
                            [ngClass]="{'selected': currentDisplayId == a?.authentication?.recordId}"
                            class="link-active"
                            (click)="currentDisplayId == a.authentication?.recordId ? clearCapture() : viewCapture(i, a.authentication?.recordId)">
                            <td>
                                <a *ngIf="a.authentication?.recordId" class="link-active" target="_blank"
                                   matTooltip="select link to open authentication in new tab"
                                   [routerLink]="['/authentication/', a.authentication?.recordId]"> {{a.authentication?.recordId}} </a>
                                <span *ngIf="!a.authentication?.recordId">Not Available</span>
                            </td>
                            <td matTooltip="click row for details">{{a.contextId}}</td>
                            <td matTooltip="click row for details">
                                <mat-icon *ngIf="a.authentication?.bestMatchId" color="primary">check</mat-icon>
                                <mat-icon *ngIf="!a.authentication?.bestMatchId" color="warn">close</mat-icon>
                            </td>
                            <td matTooltip="click row for details">{{a.itemId}}</td>
                            <td matTooltip="click row for details">{{a.stationId}}</td>
                            <td matTooltip="click row for details">{{a.createdBy}}</td>
                            <td matTooltip="click row for details">{{a.status}}</td>
                            <td matTooltip="click row for details">{{a.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card>
            <mat-paginator #paginator
                           [length]="totalAuthenticationsLength || 0"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           [showFirstLastButtons]="true"
                           [hidden]="totalAuthenticationsLength < 1"
                           (page)="pageHandler($event)"
            ></mat-paginator>

            <mat-card *ngIf="authentications &&  authentications.length === 0" class="no-match">
                <mat-card-content>
                    No Matching Records
                </mat-card-content>
            </mat-card>

            <ae-auth *ngIf="currentDisplayId" [fullView]="false" [authenticationRecord]="currentRecord!"></ae-auth>

        </div>
    </div>
</div>
