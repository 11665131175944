<div>
  <h3>FeaturePrint Stats</h3>
  <table class="table table-sm table-hover table-striped">
    <tbody>
    <tr>
      <td class="preview-label">FeaturePrints Today:</td>
      <td>{{dailyRegs}}</td>
    </tr>
    <tr>
      <td class="preview-label">Total FeaturePrints:</td>
      <td>{{totalRegs}}</td>
    </tr>
    <tr>
      <td class="preview-label">Identifications Today:</td>
      <td>{{dailyAuths}}</td>
    </tr>
    <tr>
      <td class="preview-label">Total Identifications:</td>
      <td>{{totalAuths}}</td>
    </tr>
    </tbody>
  </table>
</div>


