import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";

export class CurrentStatus {
    stationId: string;
    state: string;
    condition: string;
    description: string;
    meta: any;
    firstReported: string;
    lastReported: string;

    constructor(
        stationId: string,
        state: string,
        condition: string,
        description: string,
        meta: any,
        firstReported: string,
        lastReported: string
    ) {
        this.stationId = stationId;
        this.state = state;
        this.condition = condition;
        this.description = description;
        this.meta = meta;
        this.firstReported = firstReported;
        this.lastReported = lastReported;
    }
}

export class StationConfig {
    stationId: string;
    enabled: boolean;

    constructor(stationId: string, enabled: boolean) {
        this.stationId = stationId
        this.enabled = enabled
    }
}

@Injectable()
export class MonitoringService {

    constructor(private baseSvc: BaseService, private http: HttpClient) {
    }

    public getStationConfig(): Observable<StationConfig[]> {
        const url = this.baseSvc.customerUrl(`monitoring/device/station-config`);
        return this.http.get(url, this.baseSvc.defaultOptions())
            .pipe(catchError(err => of({"stations": []})))
            .pipe(map((response: any) => response.stations as StationConfig[]))
    }

    public getDeviceStatus(): Observable<CurrentStatus[]> {
        const params: Record<string, string[]> = {};
        const url = this.baseSvc.customerUrl(`monitoring/device/status/current`);
        const req: Observable<any> = this.http
            .get(url, this.baseSvc.defaultOptions({params: params}))
            .pipe(map((r) => r as any));

        return req;
    }
}
