import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RecordApiService} from '../../modules/record/record-api.service';
import {PhysicalRegistration} from '../../modules/record/physicalRegistration';
import {UserService} from "../../modules/user/user.service";

@Component({
  selector: 'ae-physical-reg',
  templateUrl: './physical-registration.component.html',
  styleUrls: ['./physical-registration.component.scss']
})
export class PhysicalRegistrationComponent implements OnInit {
  private registrationStream: any;
  allowStatusChange = true;

  public physicalId: string | null = null;
  public physicalRegistration: PhysicalRegistration | null = null;
  public previewUrl: string | null = null;

  @Input() fullView = true;
  @Input() showHeader = true;

  @Input() set canChangeStatus(allowed: boolean) {
    this.allowStatusChange = allowed;
  }

  @Input() set cardId(physicalId: string) {
    this.physicalId = physicalId;
    if (this.physicalId) {
      this.getRegistration();
    } else {
      this.clear();
    }
  }

  @Output() registrationChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private recordApi: RecordApiService,
              private route: ActivatedRoute,
              private userSvc: UserService) {
  }

  ngOnInit(): void {
    this.getRegistration();
    this.userSvc.currentUser().subscribe(user => {
      if (this.allowStatusChange === true) {
        this.allowStatusChange = user.roles.some(r => r.name.toLowerCase() === 'customer_admin');
      }
    })
  }

  clear(): void {
    this.physicalId = null;
    this.physicalRegistration = null;
    this.previewUrl = null;
  }

  getRegistration(): void {
    if (!this.physicalId) {
      this.physicalId = this.route.snapshot.paramMap.get('physicalId');
    }
    if (this.registrationStream) {
      this.registrationStream.unsubscribe();
    }

    if (this.physicalId) {
      this.registrationStream = this.recordApi.getRegistration(this.physicalId).subscribe(card => {
        this.physicalRegistration = card;
        this.processRegistrations();
      });
    }
  }

  private processRegistrations(): void {
    if (this.physicalRegistration?.registration) {
      this.previewUrl = this.recordApi.computePreviewUrl(this.physicalRegistration.registration.displayImageId as unknown as string
        || this.physicalRegistration.registration.registration?.previewImageId as unknown as string);
    }
  }

  canDisablePhysicalIdentifier(): boolean {
    return this.physicalRegistration?.physicalIdentifier?.status === 'ENABLED' && this.allowStatusChange;
  }

  canEnablePhysicalIdentifier(): boolean {
    return this.physicalRegistration?.physicalIdentifier?.status === 'DISABLED' && this.allowStatusChange;
  }

  disable(): void {
    if (this.physicalRegistration?.physicalIdentifier) {
      this.recordApi.disableRegistration(this.physicalRegistration.physicalIdentifier.physicalId).subscribe({
        next: r => {
          this.registrationChange.emit('physical-registration-disabled');
          this.getRegistration();
        },
        error: (error) => {
          console.log('error disabling registration', error);
        }
      });
    }
  }

  enable(): void {
    if (this.physicalRegistration?.physicalIdentifier) {
      this.recordApi.enableRegistration(this.physicalRegistration.physicalIdentifier.physicalId).subscribe({
        next: r => {
          this.registrationChange.emit('physical-registration-enabled');
          this.getRegistration();
        },
        error: (error) => {
          console.log('error enabling registration', error);
        }
      });
    }
  }
}
