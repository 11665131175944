import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {zip} from "rxjs";
import {UserService} from "./user/user.service";
import {MonitoringService, StationConfig} from "./monitoring/monitoring.service";
import {RecordApiService} from "./record/record-api.service";
import {map} from "rxjs/operators";
import {UserInfo} from "./user/user-info";
import {DataSetContext} from "./record/context-models";

export class LookupLists {
  stations: StationSearchItem[] = [];
  dataSets: string[] = [];
  contextDataSetMap: Map<string, string> = new Map<string, string>();
  registrationStatusList: StatusSearchItem[] = [];
  authenticationStatusList: StatusSearchItem[] = [];
  contexts: DataSetContext[] = [];

  constructor(stations: StationSearchItem[], dataSets: string[], contextDataSetMap: Map<string, string>, contexts: DataSetContext[]) {
    this.stations = stations;
    this.dataSets = dataSets;
    this.contextDataSetMap = contextDataSetMap;
    this.registrationStatusList = [{val: null, display: "All"}, {val: "ENABLED", display: "ENABLED"}, {
      val: "DISABLED",
      display: "DISABLED"
    }];
    this.authenticationStatusList = [{val: null, display: "All"}, {val: "COMPLETED", display: "COMPLETED"}];
    this.contexts = contexts;
  }

  public static emptyList(): LookupLists {
    return new LookupLists([], [], new Map<string, string>(), []);
  }
}

export class StationSearchItem {
  stationId: string;
  display: string;

  constructor(stationId: string, display: string) {
    this.stationId = stationId
    this.display = display
  }
}

export class StatusSearchItem {
  val: string | null;
  display: string;

  constructor(val: string | null, display: string) {
    this.val = val
    this.display = display
  }
}

@Injectable()
export class CoreService {
  constructor(
    private userSvc: UserService,
    private monitorSvc: MonitoringService,
    private recordSvc: RecordApiService) {
  }

  public getLookupLists(): Observable<LookupLists> {
    const currentUserStream = this.userSvc.currentUser();
    const userDataSetStream = this.userSvc.listDataSets();
    const stationsStream = this.monitorSvc.getStationConfig();
    const dataSetContextStream = this.recordSvc.getDataContexts();

    const tempStreams = [currentUserStream, userDataSetStream, stationsStream, dataSetContextStream]
    const streams = zip(...tempStreams);

    return streams.pipe(
      map(([currentUser, dataSetList, config, dataSetContexts]) => {
        const user = currentUser as UserInfo;
        const dataSets = dataSetList as string[];
        const stations = config as StationConfig[];
        const dsContexts = dataSetContexts as DataSetContext[];

        // todo fixme: once listDataSets() call includes '*' for users with full dataSet access remove this
        if ((user?.dataSets as string[]).some(ds => ds === '*')) {
          if (!dataSets.some(ds => ds === '*')) {
            dataSets.splice(0, 0, '*');
          }
        }
        // end todo

        const stationsList = stations.map(station => {
          return {stationId: station.stationId, display: station.stationId};
        });
        const contextMap = dsContexts
          .map(cd => {
            return [cd.contextId, cd.dataSet] as [string, string];
          });
        const contextDataSetMap = new Map(contextMap);
        const lookups = new LookupLists(stationsList, dataSets, contextDataSetMap, dsContexts);
          console.log("lookups", lookups);
        return lookups;

      }, (errors: any) => {
        console.log('Error retrieving lookup lists', ...errors);
      })
    );
  }
}
