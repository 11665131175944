<div>
    <mat-accordion>
        <mat-expansion-panel id="users-panel" [expanded]="showFilter" (opened)="showFilter = true"
                             (closed)="showFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-title>User Management</mat-panel-title>
                <mat-panel-description>User Search - Click to Toggle Search Criteria</mat-panel-description>
            </mat-expansion-panel-header>

            <br>

            <div>
                <form [formGroup]="filterForm">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="roles-select">
                                <mat-label>Select Roles</mat-label>
                                <mat-select id="filter-roles" [multiple]="true" [formControl]="filterRolesFormCtl">
                                    <mat-option *ngFor="let role of searchRoles"
                                                [value]="role.id">{{role.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Select Status</mat-label>
                                <mat-select id="filter-status" [multiple]="true" [formControl]="filterStatusFormCtl">
                                    <mat-option *ngFor="let status of statuses"
                                                [value]="status.enabled">{{status.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" class="bottom-bar-right">
                        <div class="col-md-12">
                            <button mat-stroked-button (click)="newUser();">new user</button>
                            <button mat-stroked-button color="warn" (click)="resetFilter();">reset</button>
                            <button mat-stroked-button (click)="cancelFilter();">cancel</button>
                            <button mat-stroked-button color="primary" (click)="doFilter()">search</button>
                        </div>
                    </div>
                </form>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div #usersTop class="search-filter">
    <mat-card *ngIf="users && users.length > 0">
        <div class="header-menu">
            <div class="header-menu-title"></div>
            <button *ngIf="currentUser" mat-button (click)="clearCurrent();">
                clear selection
            </button>
        </div>

        <div class="scroll-pane scroll-pane-all"
             [ngClass]="{'scroll-pane-detail': currentUser}">
            <table class="table table-sm table-hover table-striped">
                <thead>
                <tr>
                    <th>User ID</th>
                    <th>User Name</th>
                    <th>Role Names</th>
                    <th>Enabled</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let u of users; index as i" #usersList
                    [ngClass]="{'selected': currentUserId == u.userId}" class="link-active">
                    <td (click)="currentUserId == u.userId ? clearCurrent() : selectCurrent(u.userId, i)">{{u.userId}} </td>
                    <td (click)="currentUserId == u.userId ? clearCurrent() : selectCurrent(u.userId, i)">{{u.username}} </td>
                    <td (click)="currentUserId == u.userId ? clearCurrent() : selectCurrent(u.userId, i)">{{formatRoles(u.roles)}}</td>
                    <td (click)="currentUserId == u.userId ? clearCurrent() : selectCurrent(u.userId, i)">{{u?.enabled}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card>
    <mat-card *ngIf="users && users.length === 0">
        <mat-card-content>
            No Matching Users
        </mat-card-content>
    </mat-card>

    <!------------ start new user ---------------->
    <mat-card class="card-padded" *ngIf="inEdit === true || !showFilter">
        <mat-card-content>
            <div class="row">
                <div class="col-md-6" style="text-align: left">{{userFormTitle}}</div>
                <div class="col-md-6" style="text-align: right">
                    <button mat-stroked-button *ngIf="!showFilter && !inEdit" (click)="newUser();">new user</button>
                </div>
            </div>

            <form [formGroup]="userForm" autocomplete="off" *ngIf="inEdit === true">
                <div class="row" style="padding-top: 20px">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Username (email)</mat-label>
                            <input name="fem" matInput autocomplete="off" placeholder="Username"
                                   [formControl]="userNameFormCtl" email>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Select Status</mat-label>
                            <mat-select id="user-enabled" [formControl]="userEnabledFormCtl">
                                <mat-option [value]="true">Enabled</mat-option>
                                <mat-option [value]="false">Disabled</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="roles-select">
                            <mat-label>Select Roles</mat-label>
                            <mat-select id="user-roles" [multiple]="true" [formControl]="userRolesFormCtl">
                                <mat-option *ngFor="let role of availableRoles"
                                            [value]="role.id">{{role.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-error class="form-error" *ngFor="let message of formErrors">
                            Error:&nbsp;{{message}}</mat-error>
                    </div>
                </div>
                <div class="row" class="bottom-bar-right">
                    <div class="col-md-12">
                        <button *ngIf="showDelete" mat-stroked-button color="warn" (click)="doDelete();">deauthorize</button>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <button mat-stroked-button (click)="cancelEdit();">cancel</button>
                        <button mat-stroked-button color="primary" [disabled]="!canSubmit()"
                                (click)="doUpdate()">{{userIsNewCtl.value ? 'create' : 'update'}}
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <!-------------end new user------------------->


</div>
