import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RecordApiService} from '../../modules/record/record-api.service';
import {DemoItemRegistration} from '../../modules/record/physicalRegistration';

@Component({
  selector: 'ae-reg',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  public physicalRegistration!: DemoItemRegistration | undefined;
  public previewImageUrl!: string;

  @Input() fullView = true;

  @Input() set registrationRecord(registration: DemoItemRegistration | undefined) {
    this.physicalRegistration = registration;
    this.getPreview();
  }

  constructor(private recordApi: RecordApiService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  getPreview(): void {
    if (this.physicalRegistration) {
      const imageId = this.physicalRegistration.displayImageId || this.physicalRegistration.registration?.previewImageId;
      this.previewImageUrl = this.recordApi.computePreviewUrl(imageId as string) as string;
    }
  }
}
